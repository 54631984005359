// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-coleccion-index-js": () => import("./../src/pages/coleccion/index.js" /* webpackChunkName: "component---src-pages-coleccion-index-js" */),
  "component---src-pages-descarga-index-js": () => import("./../src/pages/descarga/index.js" /* webpackChunkName: "component---src-pages-descarga-index-js" */),
  "component---src-pages-dos-index-js": () => import("./../src/pages/dos/index.js" /* webpackChunkName: "component---src-pages-dos-index-js" */),
  "component---src-pages-hogar-index-js": () => import("./../src/pages/hogar/index.js" /* webpackChunkName: "component---src-pages-hogar-index-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-paper-index-js": () => import("./../src/pages/life_paper/index.js" /* webpackChunkName: "component---src-pages-life-paper-index-js" */),
  "component---src-pages-metadata-api-index-js": () => import("./../src/pages/metadata_api/index.js" /* webpackChunkName: "component---src-pages-metadata-api-index-js" */),
  "component---src-pages-road-map-index-js": () => import("./../src/pages/road_map/index.js" /* webpackChunkName: "component---src-pages-road-map-index-js" */),
  "component---src-pages-sobre-nosotros-index-js": () => import("./../src/pages/sobre_nosotros/index.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-index-js" */),
  "component---src-pages-unico-index-js": () => import("./../src/pages/unico/index.js" /* webpackChunkName: "component---src-pages-unico-index-js" */),
  "component---src-pages-white-list-index-js": () => import("./../src/pages/white_list/index.js" /* webpackChunkName: "component---src-pages-white-list-index-js" */)
}

